import eventBus from '../helpers/event-bus';

import { HOME_PAGE_RATES_CAROUSEL_UPDATE } from './home-page-event-names';

export const RatesBlockCryptoCarousel = {
  options: {
    selectors: {
      container: "#crypto-rates-block",
      scrollContainerInner: '[data-selector="rbcc-scroll-container-inner"]',
      scrollItems: '[data-selector="rbcc-scroll-item"]',
      dotsContainer: '[data-selector="rbcc-carousel-dots"]',
      dots: '[data-selector="rbcc-carousel-dots"] > button',
    },
    isEnabled: false,
    intervalId: null,
    dots: 0,
    slide: 1,
    offset: 0,
    scrollStart: 0,
    slideEverySeconds: 10,
    hovered: false,
  },

  init() {
    if (!this.isRendered()) {
      return;
    }

    this.onResize = this.onResize.bind(this);
    this.resetSlider = this.resetSlider.bind(this);
    this.onAutoSlideMouseOverListener = this.onAutoSlideMouseOverListener.bind(
      this
    );
    this.onAutoSlideMouseOutListener = this.onAutoSlideMouseOutListener.bind(
      this
    );
    this.onDotsClickListener = this.onDotsClickListener.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);

    this.initMethods();
    this.initEvents();

    eventBus.on(HOME_PAGE_RATES_CAROUSEL_UPDATE, () => {
      this.generateDots();
    });
  },

  isRendered() {
    return this.getElement("container") !== null;
  },

  // Events
  initEvents() {
    this.onResizeEvent();
    this.dots();
    this.onMouseDrag();
    this.autoSlide();
  },

  onResizeEvent() {
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },

  autoSlide() {
    const container = this.getElement("container");

    container.removeEventListener(
      "mouseover",
      this.onAutoSlideMouseOverListener
    );
    container.addEventListener("mouseover", this.onAutoSlideMouseOverListener);
    container.removeEventListener("mouseout", this.onAutoSlideMouseOutListener);
    container.addEventListener("mouseout", this.onAutoSlideMouseOutListener);

    clearInterval(this.options.intervalId);
    this.options.intervalId = setInterval(() => {
      if (this.options.hovered) {
        return;
      }

      this.nextSlide();
    }, this.options.slideEverySeconds * 1000);
  },

  dots() {
    const container = this.getElement("container");
    const dots = this.getElements("dots");

    dots.forEach((dot) => {
      dot.removeEventListener("click", this.onDotsClickListener);
      dot.addEventListener("click", this.onDotsClickListener);
    });

    window.removeEventListener(
      "update-crypto-slider",
      this.resetSlider
    );
    window.removeEventListener("resize", this.resetSlider);
    window.addEventListener("update-crypto-slider", this.resetSlider);
    window.addEventListener("resize", this.resetSlider);
  },

  onMouseDrag() {
    const scrollContainerInner = this.getElement("scrollContainerInner");

    const onMousedownListener = function (e) {
      window.addEventListener("mousemove", this.handleDrag);
      window.addEventListener("mouseup", this.handleDragEnd);

      this.handleMousedown(e);
    };

    scrollContainerInner.addEventListener("mousedown", onMousedownListener.bind(this));
  },

  offMouseDrag() {
    window.removeEventListener("mousemove", this.handleDrag);
    window.removeEventListener("mouseup", this.handleDragEnd);
  },

  onAutoSlideMouseOverListener() {
    this.options.hovered = true;
  },

  onAutoSlideMouseOutListener() {
    this.options.hovered = false;
  },

  onDotsClickListener(event) {
    this.handleSlide(parseInt(event.target.dataset.index));
  },

  // Methods
  initMethods() {
    this.generateDots();
    this.onResize();
  },

  onResize() {
    this.toggleMobileScript();

    if (window.innerWidth < 1000) {
      return this.destroySlides();
    }

    this.options.dots = this.getDotCount();

    const dots = this.getElements("dots");
    dots.forEach((dot) => {
      dot.style.display = "block";
    });
  },

  nextSlide() {
    if (this.options.dots <= 1) {
      return;
    }

    this.options.slide =
      this.options.slide < this.options.dots ? this.options.slide + 1 : 1;

    this.handleSlide(this.options.slide);
  },

  resetSlider() {
    this.generateDots();
    this.toggleMobileScript();
    this.handleSlide(1);
  },

  handleSlide(slideIndex) {
    if (!this.isEnabled || slideIndex > this.options.dots || slideIndex < 0) {
      return;
    }

    const container = this.getElement('container');

    const stepWidth = container.clientWidth;
    let dist = (slideIndex - 1) * stepWidth;

    if (slideIndex === this.getDotCount()) {
      dist = this.getReducedSlideDistance(dist);
    }

    const scrollContainerInner = this.getElement("scrollContainerInner");

    scrollContainerInner.style.transform =
      document.dir === "rtl"
        ? `translateX(${dist}px)`
        : `translateX(-${dist}px)`;

    const dots = this.getElements("dots");

    dots.forEach((dot) => {
      dot.style.display = "block";
      dot.classList.remove("is-active");

      if (parseInt(dot.dataset.index) === slideIndex) {
        dot.classList.add("is-active");
      }
    });

    this.options.slide = slideIndex;
    this.autoSlide();
  },

  getReducedSlideDistance(dist) {
    const container = this.getElement("container");
    const items = this.getVisibleScrollItems();

    const stepWidth = container.clientWidth;
    const itemsTotal = items.length;

    const itemStyle = items[0].currentStyle || window.getComputedStyle(items[0]);
    const itemWidth = items[0].clientWidth + parseInt(itemStyle.marginLeft) + parseInt(itemStyle.marginRight);

    const itemsPerContainer = parseInt(stepWidth / itemWidth);
    const itemsLeft = itemsTotal % itemsPerContainer;

    if (itemsLeft === 0) {
      return dist;
    }

    const missingItems = itemsPerContainer - itemsLeft;
    const reducedDist = dist - missingItems * itemWidth;

    return reducedDist > 0 ? reducedDist : 0;
  },

  destroySlides() {
    this.options.dots = 0;
    this.options.slide = 1;

    const scrollContainerInner = this.getElement("scrollContainerInner");
    const dots = this.getElements("dots");

    scrollContainerInner.removeAttribute("style");

    dots.forEach((dot) => {
      dot.style.display = "none";
    });

    this.initEvents();
  },

  getDotCount() {
    const container = this.getElement("container");
    const scrollContainerInner = this.getElement("scrollContainerInner");

    const stepWidth = container.clientWidth;
    const containerWidth = scrollContainerInner.clientWidth;

    return Math.ceil(containerWidth / stepWidth);
  },

  generateDots() {
    const dotsContainer = this.getElement("dotsContainer");
    const dots = this.getElements("dots");
    const dotsCount = this.getDotCount();
    const dotTemplate = dots[0].cloneNode();
  
    dotsContainer.style.visibility = dotsCount > 0 ? 'visible' : 'hidden';

    if (dotsCount > 0) {
      dots.forEach((dot) => dot.remove());
    }

    for (let i = 1; i <= dotsCount; i++) {
      const clonedDot = dotTemplate.cloneNode();

      clonedDot.classList[i === 1 ? "add" : "remove"]("is-active");
      clonedDot.dataset.index = i;
      clonedDot.textContent = i;

      dotsContainer.append(clonedDot);
    }

    this.options.dots = this.getElements("dots").length;
    this.dots();
  },

  handleMousedown(e) {
    const scrollContainerInner = this.getElement("scrollContainerInner");

    scrollContainerInner.classList.add("is-dragging");

    const style = getComputedStyle(scrollContainerInner);
    const matrix = style.transform.replace(/[^0-9\-.,]/g, "").split(",");

    const offsetLeft = matrix[12] || matrix[4] || 0;

    this.options.offset = offsetLeft - e.clientX;
    this.options.scrollStart = offsetLeft;
  },

  handleDragEnd() {
    const scrollContainerInner = this.getElement("scrollContainerInner");

    scrollContainerInner.classList.remove("is-dragging");
    this.offMouseDrag();

    const style = getComputedStyle(scrollContainerInner);

    const matrix = style.transform.replace(/[^0-9\-.,]/g, "").split(",");
    const offsetLeft = matrix[12] || matrix[4] || 0;

    let newSlide = 0;

    const nextSlide = this.options.slide + 1;
    const prevSlide = this.options.slide - 1 <= 0 ? 1 : this.options.slide - 1;

    if (parseInt(this.options.scrollStart) > parseInt(offsetLeft)) {
      newSlide = document.dir === "rtl" ? prevSlide : nextSlide;
    } else if (parseInt(this.options.scrollStart) < parseInt(offsetLeft)) {
      newSlide = document.dir === "rtl" ? nextSlide : prevSlide;
    } else {
      return;
    }

    this.handleSlide(newSlide);
  },

  handleDrag(e) {
    const container = this.getElement("container");
    const scrollContainerInner = this.getElement("scrollContainerInner");

    if (!scrollContainerInner.classList.contains("is-dragging") || !this.isEnabled) {
      return;
    }

    e.preventDefault();

    let mousePositionX = e.clientX;
    let translate = mousePositionX + this.options.offset;

    if (document.dir === "rtl") {
      if (
        translate < 0 ||
        translate > container.clientWidth * (this.options.dots - 1)
      ) {
        return;
      }
    } else if (
      translate > 0 ||
      translate < container.clientWidth * (this.options.dots - 1) * -1
    ) {
      return;
    }

    scrollContainerInner.style.transform = "translateX(" + translate + "px)";
  },

  toggleMobileScript() {
    const dotsContainer = this.getElement("dotsContainer");

    this.isEnabled = this.isElementVisible(dotsContainer);
  },

  // Queries
  getElement(elementName) {
    return document.querySelector(this.options.selectors[elementName]);
  },

  getElements(elementName) {
    return document.querySelectorAll(this.options.selectors[elementName]);
  },

  getVisibleScrollItems() {
    const items = this.getElements("scrollItems");

    const visibleItems = [];
    items.forEach(item => {
      if (this.isElementVisible(item)) {
        visibleItems.push(item);
      }
    });

    return visibleItems;
  },

  isElementVisible(element) {
    return !!(
      element.offsetWidth ||
      element.offsetHeight ||
      element.getClientRects().length
    );
  },
};
